.loader {
    width: 30px;
    height: 30px;
    border: 3px solid #eee;
    border-radius: 50%;
    border-top-color: #6c5ce7;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }