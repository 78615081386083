.tasks-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
}

.modal-content textarea {
  height: 120px;
  resize: vertical;
}

.modal-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.modal-buttons button[type="submit"] {
  background: #23ac5c;
  color: white;
}

.modal-buttons button[type="button"] {
  background: #ff4757;
  color: white;
}

.tasks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.task-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  padding: 20px;
  position: relative;
  transition: all 0.3s ease;
}

.task-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0,0,0,0.15);
}

.task-card h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: #2d3436;
}

.task-card p {
  color: #636e72;
  margin: 0 0 15px 0;
  line-height: 1.5;
}

.task-actions {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 8px;
}

.delete-btn, .complete-btn {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 18px;
}

.delete-btn {
  background: #ff4757;
  color: white;
}

.complete-btn {
  background: #23ac5c;
  color: white;
}

.delete-btn:hover, .complete-btn:hover {
  transform: scale(1.1);
}

.task-status {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: 500;

}



.open {
  background: #f1f2f6;
  color: #2d3436;
}

.closed {
  background: #cacbca;
  color: white;
}

.task-status.open {
  background-color: #23ac5c;
  color: white;
}
.task-status.closed {
  background-color: black;
  color: white;
}

.add-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 30px;
  background: #23ac5c;
  color: white;
  font-size: 20px;
  border: none;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 900;
  padding: 15px;
  font-weight: bold;
}

.add-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0,0,0,0.25);
}

@media (max-width: 768px) {
  .tasks-grid {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
    margin: 20px;
  }
  
  .add-button {
    bottom: 20px;
    right: 20px;
  }
}

@media (max-width: 480px) {
  .task-card {
    padding: 15px;
  }
  
  .task-actions {
    top: 10px;
    right: 10px;
  }
}

.logout-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ff4757;
  color: #ff4757;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.logout-button:hover {
  background-color: #ff4757;
  color: white;
  transform: scale(1.05);
}

.logout-button svg {
  transition: transform 0.3s ease;
}

.logout-button:hover svg {
  transform: translateX(2px);
}