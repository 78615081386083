.register-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
}

.register-container h1 {
  color: #2d3436;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.register-container form {
  width: 100%;
  max-width: 400px;
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.register-container input,
.register-container select {
  width: 100%;
  padding: 1rem;
  margin: 0.8rem 0;
  border: 2px solid #eee;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.register-container select {
  background-color: white;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.register-container input:focus,
.register-container select:focus {
  border-color: #6c5ce7;
  outline: none;
}

.register-container button {
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  border: none;
  border-radius: 10px;
  background: #6c5ce7;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
}

.register-container button:hover:not(:disabled) {
  background: #5b4bc4;
  transform: translateY(-2px);
}

.register-container p {
  margin-top: 1.5rem;
  color: #636e72;
}

.register-container a {
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.register-container a:hover {
  color: #5b4bc4;
}


.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loader {
  width: 18px;
  height: 18px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.register-container button:disabled {
  background: #8f85e6;
  cursor: not-allowed;
  transform: none;
}

.register-container input:disabled,
.register-container select:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .register-container {
      padding: 15px;
  }
  
  .register-container h1 {
      font-size: 2rem;
  }
  
  .register-container form {
      padding: 1.5rem;
  }
}